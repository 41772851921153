import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: { 
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    background: 'white',
  },
});