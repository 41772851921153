import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MessageIcon from '@mui/icons-material/Message'
import StoreIcon from '@mui/icons-material/Store'
import PhoneIcon from '@mui/icons-material/Phone'
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import { COLUMNS, ROWS, Actions, State } from "../useData";
import { ApplicationIcon } from '../application-icon';
import { useStyles } from "./styles"

export function Display ({ state, actions }: DisplayProps) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {ROWS_ARRAY
        .map((row, rowIndex) => (
          <div key={rowIndex} className={styles.row}>
            {row.map((_, columnIndex) => {
              const app = state.board.get(`${rowIndex}_${columnIndex}`);

              if (app == null) {
                return (
                  <div key={columnIndex} className={`${styles.cell} ${styles.emptyCell}`} />
                );
              }

              return (
                <div key={columnIndex} className={styles.cell} onClick={() => actions.start(app)}>
                  <ApplicationIcon icon={app.icon} />
                  {app.name}
                </div>
              );
            })}
          </div>
        ))
      }
      <BottomNavigation className={styles.bottomNavigation}>
        <BottomNavigationAction 
          label="Calls" 
          icon={<PhoneIcon />} 
          onClick={() => actions.start({
            name: "Calls",
            icon: "PhoneIcon",
            uri: { type: 'fake', payload: { application: "calls" } }
          })}
        />
        <BottomNavigationAction 
          label="Messages" 
          icon={<MessageIcon />} 
          onClick={() => actions.start({
            name: "Messages",
            icon: "MessageIcon",
            uri: { type: 'fake', payload: { application: "messages" } }
          })}
        />
        <BottomNavigationAction 
          label="Market" 
          icon={<StoreIcon />} 
          onClick={() => actions.start({
            name: "Market",
            icon: "StoreIcon",
            uri: { type: 'fake', payload: { application: "market" } }
          })}
        />
        <BottomNavigationAction 
          label="Account" 
          icon={<AccountCircleIcon />} 
          onClick={() => actions.start({
            name: "Account",
            icon: "AccountCircleIcon",
            uri: { type: 'fake', payload: { application: "account" } }
          })}
        />
      </BottomNavigation>
    </div>
  )
}

const ROWS_ARRAY = new Array(ROWS).fill(null).map(() => new Array(COLUMNS).fill(null));

export type DisplayProps = {
  state: State,
  actions: Actions
}

