import { EditorFields, EditorOptions } from ".";
import { Lambda } from "../../fake-api";

export class Actions {
  constructor (
    public dispatch: (action: Action) => void
  ) { }

  setInProgress = (inProgress: boolean) => {
    this.dispatch({ type: 'set-in_progress', payload: inProgress });
  }

  setError = (message: string) => {
    this.dispatch({ type: 'set-error', payload: message });
  }

  setLambda = (lambda: Lambda) => {
    this.dispatch({ type: 'set-lambda', payload: lambda });
  }

  reset = (fields: EditorFields) => {
    this.dispatch({ type: 'reset', payload: fields });
  }

  set = (field: keyof EditorFields, value: string) => {
    this.dispatch({ type: 'set', payload: { field, value } });
  }

  setOptions = <T extends keyof EditorOptions>(field: T, value: EditorOptions[T]) => {
    this.dispatch({ type: 'set-options', payload: { field, value } });
  }
}

export type Action = ActionSetInProgress
  | ActionSetError
  | ActionSetLambda
  | ActionReset
  | ActionSet
  | ActionSetOptions
;

export type ActionBase<T, P> = { type: T, payload: P };

export type ActionSetInProgress = ActionBase<"set-in_progress", boolean>;
export type ActionSetError = ActionBase<"set-error", string>;
export type ActionSetLambda = ActionBase<"set-lambda", Lambda>;
export type ActionReset = ActionBase<"reset", EditorFields>;
export type ActionSet = ActionBase<"set", { field: keyof EditorFields, value: string }>;
export type ActionSetOptions = ActionBase<"set-options", { field: keyof EditorOptions, value: unknown }>;