import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO } from "..";

@ApiEntity("Groups", "Methods for managing groups")
export class APIGroups {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Method for create group",
    [ "personal#*", "personal#groups" ],
    {
      title: "My group",
      payload: { answer: 42 }
    } as GroupCreateRequest,
    { 
      id: "<uuid>",
      owner: "alice",
      title: "My group",
      isPersonal: false,
      isActive: true,
      payload: { answer: 42 }
    } as Group
  )
  async create (request: GroupCreateRequest): Promise<Group> {
    return await this.api.execute(`/groups`, "POST", null, request);
  }

  @ApiMethod(
    "Method for update group",
    [ "personal#*", "personal#groups" ],
    {
      id: "<group-id>",
      title: "My group",
      payload: { answer: 42 }
    } as GroupUpdateRequest,
    undefined
  )
  async update (request: GroupUpdateRequest): Promise<void> {
    await this.api.execute(`/groups`, "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete group (allowed only to the owner of the group)",
    [ "personal#*", "personal#groups" ],
    "<group-id>",
    undefined
  )
  async delete (id: string): Promise<void> {
    await this.api.execute(`/groups/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for restore group (allowed only to the owner of the group)",
    [ "personal#*", "personal#groups" ],
    "<group-id>",
    undefined
  )
  async restore (id: string): Promise<void> {
    await this.api.execute(`/groups/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method for get group (allowed only to the member of the group)",
    [ "personal#*", "personal#groups" ],
    "<group-id>",
    {
      id: "<uuid>",
      owner: "alice",
      title: "My group",
      isPersonal: false,
      isActive: true,
      payload: { answer: 42 }
    } as Group
  )
  async get <T>(id: string): Promise<Group<T>> {
    return await this.api.execute(`/groups/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting the number of groups by filter (all parameters are optional)",
    [ "personal#*", "personal#groups" ],
    {
      q: "Search filter",
      owner: "group owner",
      is_personal: false,
      is_active: true
    },
    { count: 42 }
  )
  async count (filter: GroupsFilterCount): Promise<{ count: number }> {
    return await this.api.execute(`/groups/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for getting a list of groups (returns only the groups the user is a member of)",
    [ "personal#*", "personal#groups" ],
    {
      q: "Search filter",
      owner: "group owner",
      is_personal: false,
      is_active: true,
      limit: 10,
      offset: 0
    },
    [
      {
        id: "<uuid>",
        owner: "alice",
        title: "My group",
        isPersonal: false,
        isActive: true,
        payload: { answer: 42 }
      }
   ] as Group[]
  )
  async list (filter: GroupsFilter): Promise<Group[]> {
    return await this.api.execute(`/groups`, "GET", filter, null);
  }
}

export type GroupCreateRequest<T = GroupPayload> = {
  title: string,
  payload: T
};

export type GroupUpdateRequest<T = GroupPayload> = {
  id: string,
  title: string,
  payload: T
};

export type Group<T = GroupPayload> = {
  id: string,
  owner: string,
  title: string,
  isPersonal: boolean,
  isActive: boolean,
  payload: T
};

export type GroupsFilterCount = {
  q?: string | null,
  owner?: string | null,
  is_personal?: boolean | null,
  is_active?: boolean | null,
};

export type GroupsFilter = GroupsFilterCount & {
  limit: number,
  offset: number
};

export type GroupPayload = Record<string, unknown>;