import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MessageIcon from '@mui/icons-material/Message'
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import PhoneIcon from '@mui/icons-material/Phone'
import SimCardIcon from '@mui/icons-material/SimCard'
import StoreIcon from '@mui/icons-material/Store'
import WalletIcon from '@mui/icons-material/Wallet'
import Avatar from '@mui/material/Avatar'
import { FunctionComponent } from 'react'

export function ApplicationIcon ({ icon }: DisplayIconProps) {
  const Component = ICONS[icon];
  if (Component == null) {
    return (
      <Avatar>
        {icon[0]}
      </Avatar>
    )
  }

  return (
    <Avatar>
      <Component />
    </Avatar>
  );
}

export type DisplayIconProps = { icon: string };

const ICONS: Record<string, FunctionComponent> = {
  AccountBoxIcon,
  AccountCircleIcon,
  MessageIcon,
  NewReleasesIcon,
  PermContactCalendarIcon,
  PhoneIcon,
  SimCardIcon,
  StoreIcon,
  WalletIcon,
};