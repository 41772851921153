import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import { SignPageProps } from "..";
import { LoginPassword } from "../LoginPassword";
import Alert from "@mui/material/Alert";

export function SignUp ({ state, actions, onSignUp }: SignPageProps & { onSignUp: () => void }) {
  const styles = useStyles();

  return (
    <>
      <Typography variant="h5">
        Sign Up
      </Typography>
      <LoginPassword
        login={state.login} 
        password={state.password} 
        passwordVisible={state.passwordVisible}
        disabled={state.inProgress}
        error={state.error}
        onLoginChanged={actions.handleLoginChanged}
        onPasswordChanged={actions.handlePasswordChanged}
        onShowPassword={actions.handleShowPassword}
        onHidePassword={actions.handleHidePassword}
      />
      <TextField 
        fullWidth
        label="Invite"
        color={state.error?.field === 'invite' ? 'error' : 'primary'}
        value={state.invite}
        disabled={state.inProgress}
        onChange={actions.handleInviteChanged}
      />
      <div className={styles.actions}>
        <Button disabled={state.inProgress} onClick={actions.gotoSignIn}>
          Sign in
        </Button>
        <Button variant="outlined" disabled={state.inProgress} onClick={onSignUp}>
          Sign up
        </Button>
      </div>
      {state.error != null 
        ? <Alert severity="error">{state.error.message}</Alert>
        : null
      }
    </>
  )
}