import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FakeApi } from "../fake-api";
import { State } from "../useData"
import { useStyles } from "./styles";
import { useData } from './useData';

export function Editor ({ api, selected, onClose }: EditorProps) {
  const { 
    state,
    handleConsoleShow,
    handleConsoleHide,
    handleTabChanged,
  } = useData(api, selected?.package || '', selected?.id || '');
  const styles = useStyles();

  if (selected == null) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Button onClick={onClose}>
          Lambdas
        </Button>
        <Button>
          {selected.package}
        </Button>
        <Button>
          {selected.id}
        </Button>
      </Breadcrumbs>
      <div className={styles.main}>
      </div>
      <div className={`${styles.bottom} ${!state.options.consoleVisible ? styles.bottomHidden : ''}`}>
        <div 
          className={styles.tabs} 
          onClick={!state.options.consoleVisible ? handleConsoleShow : undefined}
          onDoubleClick={state.options.consoleVisible ? handleConsoleHide : undefined}
        >
          <Tabs value={state.options.tab} sx={{ flex: 1 }} onChange={handleTabChanged}>
            <Tab value="main" label="Main" />
            <Tab value="payload" label="Payload" />
          </Tabs>
          {!state.options.consoleVisible 
            ? null
            : (
              <IconButton onClick={handleConsoleHide}>
                <ArrowCircleDownIcon />
              </IconButton>
            )
          }
        </div>
      </div>
    </div>
  )
}

export type EditorProps = {
  api: FakeApi,
  selected: State['selected'],
  onClose: () => void
}