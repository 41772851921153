import { Lambda } from "../fake-api";
import { State } from "./state";

export class Actions {
  constructor (
    public dispatch: (action: Action) => void
  ) { }

  setQ = (q: string) => {
    this.dispatch({ type: 'set-q', payload: q });
  }

  setPage = (page: number) => {
    this.dispatch({ type: 'set-page', payload: page });
  }

  setFilterVisible = (visible: boolean) => {
    this.dispatch({ type: 'set-filter-visible', payload: visible });
  }

  setIsReady = (isReady: boolean) => {
    this.dispatch({ type: 'set-is-ready', payload: isReady });
  }

  setList = (q: string, list: Lambda[], count: number) => {
    this.dispatch({ type: 'set-list', payload: { q, list, count } });
  }

  setSelected = (selected: State['selected']) => {
    this.dispatch({ type: 'set-selected', payload: selected });
  }

  setError = (q: string, error: string) => {
    this.dispatch({ type: 'set-error', payload: { q, error } });
  }
}

export type Action = setQ
  | setPage
  | setFilterVisible
  | setIsReady
  | setList
  | setSelected
  | setError;

export type ActionBase<T, P> = {
  type: T,
  payload: P
}

export type setQ = ActionBase<'set-q', string>;
export type setPage = ActionBase<'set-page', number>;
export type setFilterVisible = ActionBase<'set-filter-visible', boolean>;
export type setIsReady = ActionBase<'set-is-ready', boolean>;
export type setList = ActionBase<'set-list', { q: string, list: Lambda[], count: number }>;
export type setSelected = ActionBase<'set-selected', State['selected']>;
export type setError = ActionBase<'set-error', { q: string, error: string }>;