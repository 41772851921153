import { AppProps } from '../props'
import { Calls } from './Calls'
import { Contacts } from './Contacts'
import { Lambda } from './Lambda'
import { Market } from './Market'

export const FAKE_APPS: Record<string, (props: AppProps) => JSX.Element> = {
  calls: Calls,
  contacts: Contacts,
  lambda: Lambda,
  market: Market,
}