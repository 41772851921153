export default `
# Invites database

\`\`\`mermaid
erDiagram
Invites {
  secret VARCHAR
  message VARCHAR
  bonus_currency VARCHAR
  bonus_amount DECIMAL
  payload JSONB
}
History {
  date TIMESTAMP
  session VARCHAR
  type VARCHAR
  payload JSONB
}
\`\`\`
`