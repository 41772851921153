import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { SignPageProps } from "..";
import { LoginPassword } from "../LoginPassword";
import { useStyles } from "./styles";
import Alert from "@mui/material/Alert";

export function SignIn ({ state, actions, onSignIn }: SignPageProps & { onSignIn: () => void }) {
  const styles = useStyles();

  return (
    <>
      <Typography variant="h5">
        Sign In
      </Typography>
      <LoginPassword
        login={state.login} 
        password={state.password} 
        passwordVisible={state.passwordVisible}
        disabled={state.inProgress}
        error={state.error}
        onLoginChanged={actions.handleLoginChanged}
        onPasswordChanged={actions.handlePasswordChanged}
        onShowPassword={actions.handleShowPassword}
        onHidePassword={actions.handleHidePassword}
      />
      <div className={styles.actions}>
        <Button disabled={state.inProgress} onClick={actions.gotoSignUp}>
          Sign up
        </Button>
        <Button variant="outlined" disabled={state.inProgress} onClick={onSignIn}>
          Sign in
        </Button>
      </div>
      {state.error != null 
        ? <Alert severity="error">{state.error.message}</Alert>
        : null
      }
    </>
  )
}