import { ApiMethodDescription } from "./method";
import { API_ENTITY_SYMBOL } from "./symbols";

export const ApiEntity = <T>(
  name: string,
  description: string
) => 
  (constructor: T) => {
    const ctr = constructor as ApiEntityConstructor;

    ctr[API_ENTITY_SYMBOL] = ctr[API_ENTITY_SYMBOL] || {} as ApiEntityDescription;
    ctr[API_ENTITY_SYMBOL].name = name;
    ctr[API_ENTITY_SYMBOL].description = description;
    ctr[API_ENTITY_SYMBOL].methods = ctr[API_ENTITY_SYMBOL].methods || [];
  }

export type ApiEntityConstructor = {
  [API_ENTITY_SYMBOL]: ApiEntityDescription;

  new (...args: unknown[]): { }
}

export type ApiEntityDescription = {
  name: string; 
  description: string;
  methods: ApiMethodDescription[]
}
