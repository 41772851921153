import { Dispatch } from "./reducer";
import { State } from "./state";

export interface IAction<T, P> {
  type: T,
  payload: P
};

export type Action = AGoto 
  | ASetKey 
  | ASetPasswordVisible 
  | ASetError
  | ASetInProgress;

export type AGoto = IAction<"goto", State['page']>;
export type ASetKey = IAction<"set", { key: "login" | "password" | "invite", value: string }>;
export type ASetPasswordVisible = IAction<"set-password-visible", boolean>;
export type ASetError = IAction<"set-error", { field: string | null, message: string }>;
export type ASetInProgress = IAction<"set-in-progress", boolean>;

export class Actions {
  constructor (
    private dispatch: Dispatch
  ) { }

  gotoSignIn = () => {
    this.dispatch({ type: 'goto', payload: "sign-in" })
  }

  gotoSignUp = () => {
    this.dispatch({ type: 'goto', payload: "sign-up" })
  }

  handleLoginChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.dispatch({ type: 'set', payload: { key: "login", value: e.target.value } });
  }

  handlePasswordChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.dispatch({ type: 'set', payload: { key: "password", value: e.target.value } });
  }

  handleInviteChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.dispatch({ type: 'set', payload: { key: "invite", value: e.target.value } });
  }

  handleShowPassword = () => {
    this.dispatch({ type: 'set-password-visible', payload: true });
  }

  handleHidePassword = () => {
    this.dispatch({ type: 'set-password-visible', payload: false });
  }

  setError = (message: string, field: string | null = null) => {
    this.dispatch({ type: 'set-error', payload: { field, message } });
  }

  setInProgress = (inProgress: boolean) => {
    this.dispatch({ type: 'set-in-progress', payload: inProgress });
  }
}