export default `
# Password sign in

## Hashes for test users

All users have the same password (lfk11kos)

- alice hash: d1f3f3bf279676a77d81d17b753f0ac8
- bob hash: 41237ca6302ac112ba3bf5a201719411

## Get salt

\`\`\`method:sso_password_salt
\`\`\`

## Calc password hash

hash = md5(salt + password)

## Sign in

\`\`\`method:sso_password_signIn
\`\`\`
`