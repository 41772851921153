import { createUseStyles } from 'react-jss';
import { DISPLAY_STYLES } from '../../../styles';

export const useStyles = createUseStyles({
  root: { 
    marginTop: 64,
    paddingTop: 16,
    ...DISPLAY_STYLES
  },
  message: {
    position: 'absolute',
    left: '50%',
    top: 96,
    transform: 'translate(-50%, -50%)'
  }
});