import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO } from "..";

@ApiEntity("Sessions", "Methods for managing sessions")
export class APISessions {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Method for get session",
    [ "personal#sessions" ],
    "<session-id>",
    { 
      id: "<group-id>:<user-id>",
      auth: "<group-id>",
      start: "start date",
      due: "due date",
    } as Session
  )
  async get (id: string): Promise<Session> {
    return await this.api.execute(`/sessions/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting the number sessions",
    [ "personal#sessions" ],
    { auth: "<auth-id> or null" } as SessionsFilterCount,
    { count: 42  }
  )
  async count (filter: SessionsFilterCount): Promise<{ count: number }> {
    return await this.api.execute(`/sessions/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for getting a list sessions",
    [ "personal#sessions" ],
    {
      auth: "<auth-id> or null",
      limit: 10,
      offset: 0
    } as SessionsFilter,
    [
      { 
        id: "<group-id>:<user-id>",
        auth: "<group-id>",
        start: "start date",
        due: "due date",
      }
    ] as Session[]
  )
  async list (filter: SessionsFilter): Promise<Session[]> {
    return await this.api.execute(`/sessions`, "GET", filter, null);
  }

  @ApiMethod(
    "Close session",
    [ "personal#sessions" ],
    "<session-id>",
    undefined
  )
  async close(id: string): Promise<undefined> {
    return this.api.execute(`/sessions/${id}`, "DELETE", null, null);
  }
}

export type Session = {
  id: string,
  auth: string,
  start: string,
  due: string
};

export type SessionsFilterCount = {
  auth?: string | null,
};

export type SessionsFilter = SessionsFilterCount & {
  limit: number,
  offset: number
};