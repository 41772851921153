import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO, Session, User, UserPayload } from "..";

@ApiEntity("User", "Methods for getting user data")
export class APIUser {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Get user info",
    [],
    null,
    {  
      session: { id: "<session-id>", auth: "<session-auth>", start: "<session-start>", due: "<session-due>" },
      user: { id: "<user-id>", status: 0, isActive: true, payload: { a: 42 } }
    } as UserMeResponse
  )
  async me<T = UserPayload> (_?: null | undefined): Promise<UserMeResponse<T>> {
    return this.api.execute("/user/me", "GET", null, null);
  }

  @ApiMethod(
    "Close current session",
    [ "personal#sign-out" ],
    null,
    null
  )
  async signOut(_?: null | undefined): Promise<undefined> {
    return this.api.execute("/user/sign-out", "DELETE", null, null);
  }
}

export type UserMeResponse<T = UserPayload> = {
  session: Session,
  user: User<T>
};
