import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO } from "..";

@ApiEntity("Permissions", "Methods for managing group permissions")
export class APIPermissions {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Method for add permission to group",
    [ "<entity>#*" ],
    {
      group: "<group-id>",
      entity: "wallets:<my-wlt>",
      permission: "read"
    } as PermissionCreateRequest,
    { 
      id: "<group-id>:wallets:<my-wlt>:read",
      group: "<group-id>",
      entity: "wallets:<my-wlt>",
      permission: "read"
    } as Permission
  )
  async create <T>(request: PermissionCreateRequest<T>): Promise<Permission> {
    return await this.api.execute(`/permissions`, "POST", null, request);
  }

  @ApiMethod(
    "Method for delete permission from group (allowed only for group owner)",
    [ "<entity>#*" ],
    "<permission-id>",
    undefined
  )
  async delete (id: string): Promise<void> {
    await this.api.execute(`/permissions/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for get permission (allowed only for group member)",
    [ "personal#groups" ],
    "<permission-id>",
    { 
      id: "<group-id>:<user-id>",
      group: "<group-id>",
      entity: "wallet:my-wlt",
      permission: "read",
      payload: { }
    } as Permission
  )
  async get (id: string): Promise<Permission> {
    return await this.api.execute(`/permissions/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting the number of permissions in group (allowed only for group member)",
    [ "personal#groups" ],
    {
      group: "<group-id>",
      q: "user query",
    } as PermissionsFilterCount,
    { count: 42  }
  )
  async count (filter: PermissionsFilterCount): Promise<{ count: number }> {
    return await this.api.execute(`/permissions/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for getting a list of permissions in group (allowed only for group member)",
    [ "personal#groups" ],
    {
      group: "<group-id>",
      q: "user query",
      limit: 10,
      offset: 0
    } as PermissionsFilter,
    [
      { 
        id: "<group-id>:<user-id>",
        group: "<group-id>",
        entity: "wallet:my-wlt",
        permission: "read",
        payload: { }
      }
    ] as Permission[]
  )
  async list (filter: PermissionsFilter): Promise<Permission[]> {
    return await this.api.execute(`/permissions`, "GET", filter, null);
  }
}

export type PermissionCreateRequest<T = PermissionPayload> = {
  group: string,
  entity: string,
  permission: string,
  payload: T
};

export type Permission<T = PermissionPayload> = {
  id: string,
  group: string,
  entity: string,
  permission: string,
  payload: T
};

export type PermissionsFilterCount = {
  group: string,
  q?: string | null,
};

export type PermissionsFilter = PermissionsFilterCount & {
  limit: number,
  offset: number
};

export type PermissionPayload = Record<string, unknown>;