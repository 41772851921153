import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiInvites } from "..";

@ApiEntity("Invites", "Methods for manipulate with invites")
export class APIInvites {
  constructor (
    public api: ApiInvites
  ) { }

  @ApiMethod(
    "Method for create invite",
    [ "invite#create" ],
    {
      message: "Bonus is optional 'field'",
      bonus: {
        currency: "€",
        amount: 100
      }
    } as InvitesCreateRequest,
    undefined
  )
  async create (request: InvitesCreateRequest): Promise<void> {
    return this.api.execute(`/invites`, "POST", null, request);
  }

  @ApiMethod(
    "Method for use invite",
    [ ],
    {
      invite: "device_id",
      id: "user_id",
      passwordSalt: "salt",
      passwordHash: "hash",
    } as InvitesUseRequest,
    { 
      message: "",
      bonus: {
        currency: "€",
        amount: 100
      }
    } as InvitesUseResponse
  )
  async use (request: InvitesUseRequest): Promise<InvitesUseResponse> {
    return this.api.execute(`/invites`, "PUT", null, request);
  }
}

export type InvitesCreateRequest = {
  message: string;
  bonus?: InvitesBonus | null | undefined
};

export type InvitesCreateResponse = {
  message: string;
};

export type InvitesUseRequest = {
  invite: string,
  id: string,
  passwordSalt: string,
  passwordHash: string,
};

export type InvitesUseResponse = {
  message: string;
  bonus?: InvitesBonus | null | undefined
};

export type InvitesBonus = {
  currency: string;
  amount: number | string;
};
