import AppBar from '@mui/material/AppBar';
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { Actions, State } from "../useData";
import { ApplicationIcon } from '../application-icon';
import { useStyles } from "./styles";
import { FAKE_APPS, NotImplemented, Wasm } from '../../apps';

export function Apps ({ state, actions }: AppsProps) {
  const styles = useStyles();

  if (state.active == null) {
    return null;
  }

  return (
    <div className={styles.root}>
      <AppBar position="static">
        <Toolbar>
          <ApplicationIcon icon={state.active.icon} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginLeft: 2 }}>
            {state.active.name}
          </Typography>
          <IconButton onClick={actions.stop} color="inherit">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AppsApp state={state} actions={actions} />
    </div>
  )
}

export type AppsProps = {
  state: State,
  actions: Actions,
};

function AppsApp ({ state, actions }: AppsAppProps) {
  const type = state.active?.uri.type || '';
  const application = (state.active?.uri.payload as unknown as Record<'application', string>)['application'] || '';

  if (type === 'wasm') {
    return <Wasm state={state} actions={actions} />
  }

  if (type === 'fake') {
    const AppComponent = FAKE_APPS[application];
    if (AppComponent == null) {
      return <NotImplemented message={`Appliction "${application}" not found`} />
    }

    return <AppComponent state={state} actions={actions} />
  }

  return <NotImplemented message={`Unhandled appliction type "${type}"`} />
}

type AppsAppProps = {
  state: State,
  actions: Actions
};