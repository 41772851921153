import { Apps } from "./Apps";
import { Display } from "./Display";
import { Sign } from "./Sign"
import { useData } from "./useData"

export function Appliction () {
  const { state, actions } = useData();

  if (!state.isUserReady) {
    return (
      <div style={{ position: "fixed", left: "50vw", top: "50vh", transform: "translate(-50%, -50%)" }}>
        Loading...
      </div>  
    )
  }

  if (state.user == null) {
    return (<Sign onUserChanged={actions.setUser} />);
  }

  return (
    <>
      <Display state={state} actions={actions} />
      <Apps state={state} actions={actions} />
    </>
  )
}