import { ArticleDescription } from "./article";
import { API_SERVICE_SYMBOL } from "./symbols";

export const ApiService = <T>(
  name: string,
  description: string
) => 
  (constructor: T) => {
    const ctr = constructor as ApiServiceConstructor;

    ctr[API_SERVICE_SYMBOL] = ctr[API_SERVICE_SYMBOL] || { name, description, articles: [] }
    ctr[API_SERVICE_SYMBOL].name = name;
    ctr[API_SERVICE_SYMBOL].description = description;
  }

export type ApiServiceConstructor = {
  [API_SERVICE_SYMBOL]: ApiServiceDescription;

  new (...args: unknown[]): {}
}

export type ApiServiceDescription = {
  name: string,
  description: string,
  articles: ArticleDescription[]
}