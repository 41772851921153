import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import JsSIP from 'jssip';
import { AppProps } from "../../props";
import { OutgoingAckEvent, RTCSession } from "jssip/lib/RTCSession";

export function useData (props: AppProps) {
  const startedPhoneNumber = ((props.state.active?.uri.payload || { }) as unknown as Record<'phone', string>).phone || '';
  const [ phone, setPhone ] = useState(startedPhoneNumber);
  const [ isConnected, setIsConnected ] = useState(UA.isRegistered());
  const history = useMemo(
    () => HISTORY.filter((x) => 
      x.phone.startsWith(phone) 
      || (x.name || '').toLowerCase().indexOf(phone.toLowerCase()) > -1
    ),
    [ phone ]
  );
  const [ session, setSession ] = useState(null as (RTCSession | null));
  const [ duration, addDuration ] = useReducer((a: number, b: number | null) => b == null ? 0 : a + b, 0);
  
  const handlePhoneChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => { setPhone(e.currentTarget.value); },
    [ setPhone ]
  );
  const handleCall = useCallback(
    () => {
      let intervalId: string | null = null;
      const onEnded = () => {
        setSession(null);
        addDuration(null);
        if (intervalId != null) {
          clearInterval(intervalId);
        }
      }

      const eventHandlers = {
        'failed': onEnded,
        'ended': onEnded,
        'confirmed': function(e: OutgoingAckEvent) {
          addDuration(0.00000001);
          intervalId = setInterval(() => addDuration(0.5), 500) as unknown as string;
        }
      };

      setSession(
        UA.call(
          `sip:${phone}`, 
          {
            eventHandlers,
            mediaConstraints : { 
              audio: true 
            }
          }
        )
      );
    },
    [ phone ]
  );

  useEffect(() => {
    const onRegistered = () => setIsConnected(true);

    UA.on('registered', onRegistered);

    return () => {
      UA.off('registered', onRegistered);
    }
  }, [ setIsConnected ]);

  return {
    isConnected,
    phone,
    history,
    session,
    duration: `${Math.floor(duration / 60)}:${Math.floor(duration % 60)}`,
    setPhone,
    handlePhoneChange,
    handleCall
  }
}

const CONNECTION = new JsSIP.WebSocketInterface('wss://fs.dev.lightsim.io:7443');
const CONFIGURATION = {
  sockets  : [ CONNECTION ],
  uri      : 'sip:3727777@fs.dev.lightsim.io',
  password : 'lfk11kos'
};
const UA = new JsSIP.UA(CONFIGURATION);
UA.start();

UA.on('registrationFailed', (...a) => { console.log('registrationFailed', ...a) });

const HISTORY: HistoryItem[] = [
  {
    type: 'call-in',
    name: 'Janifer Aniston',
    phone: '79051144522',
    duration: '0:48'
  },
  {
    type: 'call-in',
    name: 'Sбerбank',
    phone: '900',
    duration: '13:22'
  },
  {
    type: 'call-out',
    name: 'Si',
    phone: '37257032800',
    duration: '0:22'
  },
  {
    type: 'call-in',
    name: 'Si',
    phone: '37257032800',
    duration: '0:18'
  },
];

export type HistoryItem = {
  type: 'call-in' | 'call-out',
  name?: string | null | undefined,
  phone: string,
  duration: string
};