import { Action, State } from ".";

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set-in_progress':
      return { ...state, inProgress: action.payload };

    case 'set-error':
      return { ...state, error: action.payload, inProgress: false };

    case 'set-lambda':
      return { ...state, lambda: action.payload, inProgress: false };

    case 'reset':
      return { 
        ...state,  
        name: action.payload.name,
        description: action.payload.description,
        tags: action.payload.tags,
        code: action.payload.code,
        payload: action.payload.payload
      };

    case 'set':
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };

    case 'set-options':
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.field]: action.payload.value
        }
      }

    default:
      throw new Error(`Unhandled action type: ${JSON.stringify(action['type'])}`);
  }
}
