export default `
# SSO

Microservice for managing users and their permissions.

## Features

- Sessions managment
- Groups (and private groups)
- Plural groups creation (entities/create)
`