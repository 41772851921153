import { API, ApiService, Article } from '@narayana/api';
import docs from './__docs';
import { APIEntities } from './entities';
import { APIGroups } from './groups';
import { APIGroupUsers } from './groupUsers';
import { APIPassword } from './password';
import { APIPermissions } from './permissions';
import { APISessions } from './sessions';
import { APITokens } from './tokens';
import { APIUser } from './user';
import { APIUsers } from './users';

export * from './entities';
export * from './groups';
export * from './groupUsers';
export * from './password';
export * from './permissions';
export * from './sessions';
export * from './tokens';
export * from './user';
export * from './users';

@Article("database", "Database", docs.database)
@Article("password-sign-in", "Password sign in", docs.sign_in)
@Article("todo", "ToDo", docs.todo)
@ApiService("SSO", docs.readme)
export class ApiSSO extends API {
  public entities = new APIEntities(this);
  public groups = new APIGroups(this);
  public groupUsers = new APIGroupUsers(this);
  public password = new APIPassword(this);
  public permissions = new APIPermissions(this);
  public sessions = new APISessions(this);
  public tokens = new APITokens(this);
  public user = new APIUser(this);
  public users = new APIUsers(this);

  constructor (prefix: string) {
    super(prefix);
  }
}