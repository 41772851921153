import { ApiError } from '@narayana/api';
import md5 from 'md5';
import { useCallback, useMemo, useReducer } from "react";
import {  v4 as uuidV4 } from 'uuid';

import { reducer } from "./reducer";
import { defaultState } from "./state";
import { Actions } from "./actions";
import { API } from "../../../api";
import { SignProps } from '..';
import { User } from '@narayana/sso-api';

export * from "./actions"
export * from "./reducer"
export * from "./state"

export const useData = ({ onUserChanged }: SignProps) => {
  const [ state, dispatch ] = useReducer(reducer, defaultState())
  const actions = useMemo(() => new Actions(dispatch), [ dispatch ]);

  const handleSignIn = useCallback(
    () => {
      (async () => {
        try {
          actions.setInProgress(true);

          let salt: string | null = null;
          try {
            const response = await API.sso.password.salt(state.login);
            salt = response.salt;
          } catch (e) {
            if ((e as ApiError).status === 404) {
              throw new Error(`User with login ${JSON.stringify(state.login)} not found`)
            }

            console.error("getSalt error:", e);
            throw new Error("Something went wrong. Try a little later")
          }
          
          let user: User | null = null;
          try {
            const response = await API.sso.password.signIn({ 
              user: state.login, 
              hash: md5(`${salt}${state.password}`) 
            });
            user = response.user;
          } catch (e) {
            if ((e as ApiError).status === 403) {
              throw new Error(`Invalid login or password`)
            }

            console.error("signIn error:", e);
            throw new Error("Something went wrong. Try a little later")
          }

          onUserChanged(user, "");
        } catch(e) {
          actions.setError((e as Error).message);
        } finally {
          actions.setInProgress(false);
        }
      })()
    },
    [ actions, state.login, state.password, onUserChanged ]
  );

  const handleSignUp = useCallback(
    () => {
      (async () => {
        try {
          actions.setInProgress(true);

          const passwordSalt = uuidV4();
          const passwordHash = md5(`${passwordSalt}${state.password}`);
          let message = "";

          try {
            const response = await API.invites.invites.use({ 
              id: state.login,
              invite: state.invite,
              passwordSalt,
              passwordHash
            });
            message = response.message;
          } catch (e) {
            if ((e as ApiError).status === 404) {
              throw new Error(`Invalid invlte ${JSON.stringify(state.invite)}`)
            }

            console.error("signUp/invitesUse error:", e);
            throw new Error(`User ${JSON.stringify(state.login)} already exists`);
          }

          let user: User | null = null;
          try {
            const response = await API.sso.password.signIn({ 
              user: state.login, 
              hash: passwordHash
            });
            user = response.user;
          } catch (e) {
            console.error("signUp/passwordSignIn error:", e);
            throw new Error("Something went wrong. Try a little later");
          }

          onUserChanged(user, message);
        } catch(e) {
          actions.setError((e as Error).message)
        } finally {
          actions.setInProgress(false);
        }
      })()
    },
    [ actions, state.login, state.password, state.invite, onUserChanged ]
  );

  return {
    state,
    actions,
    handleSignIn,
    handleSignUp
  };
};