import { ApiError } from "@narayana/api";

export class FakeApi {
  private items: Lambda[] = [
    { package: "test", id: "example", name: "Example", description: "", tags: [ "public" ], code: "1", payload: { } },
    { package: "test", id: "example2", name: "Example", description: "", tags: [ "public" ], code: "2", payload: { } },
    { package: "test2", id: "private-example", name: "Example", description: "", tags: [ "any" ], code: "3", payload: { } },
  ];

  constructor (public _prefix: string) {
  }

  async get (id: string): Promise<Lambda> {
    await this.wait();

    let result = this.items.find(x => x.id === id);
    if (result == null) {
      throw new ApiError(403, "Not found");
    }

    return result;
  }

  async list (q: string, tags: string[], offset: number, limit: number): Promise<Lambda[]> {
    await this.wait();

    return this.items.filter((x) => {
      if (q.length > 0 && x.name.indexOf(q) < 0) {
        return false;
      }

      if (tags.length > 0) {
        const tagsSet = new Set(tags);

        let hasIntersection = false;
        for (const tag of x.tags) {
          if (tagsSet.has(tag)) {
            hasIntersection = true;
            break;
          }
        }

        if (!hasIntersection) {
          return false;
        }
      }

      return true;
    })
  }

  async count (q: string, tags: string[]): Promise<number> {
    return (await this.list(q, tags, 0, this.items.length)).length;
  }

  async create (lambda: Lambda): Promise<void> {
    await this.wait();
    
    for (let i = 0; i < this.items.length; i++) {
      const current = this.items[i];
      if (current.package === lambda.package && current.id === lambda.id) {
        throw new ApiError(409, "Already exists");
      }
    }

    this.items.push(lambda);
  }

  async update (lambda: Lambda): Promise<void> {
    await this.wait();
    
    for (let i = 0; i < this.items.length; i++) {
      const current = this.items[i];
      if (current.package === lambda.package && current.id === lambda.id) {
        this.items[i] = lambda;
        return;
      }
    }

    throw new ApiError(403, "Not found");
  }

  private wait (): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, 250 + Math.random() * 500));
  }
}

export type Lambda = {
  package: string,
  id: string,
  name: string,
  description: string,
  tags: string[],
  code: string,
  payload: Record<string, unknown>
};