import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import { AppProps } from "../..";
import { useStyles } from "./styles";
import { useData } from "./useData";
import { Editor } from "./editor";
import Alert from "@mui/material/Alert";

export function Lambda (_: AppProps) {
  const { state, api, handleQChanged, handleSelectedChanged } = useData();
  const styles = useStyles();

  if (state.selected != null) {
    return (
      <Editor 
        key={JSON.stringify(state.selected)} 
        api={api}
        selected={state.selected} 
        onClose={() => handleSelectedChanged(null)} 
      />
    );
  }

  return (
    <div className={styles.root}>
      <TextField 
        fullWidth
        label="Query"
        value={state.q}
        onChange={handleQChanged}
      />
      {!state.isReady 
        ? <div className={styles.message}>Loading</div>
        : (
          <>
            {state.error == null 
              ? null
              : (<Alert severity="error">{state.error}</Alert>)}
            {state.list.length === 0 
              ? (<div className={styles.message}>Lambdas not found</div>)
              : (
                <List>
                  {state.list.map((lambda, index) => (
                    <ListItemButton key={index} onClick={() => handleSelectedChanged({ package: lambda.package, id: lambda.id })}>
                      <ListItemAvatar>
                        λ
                      </ListItemAvatar>
                      <ListItemText 
                        primary={`${lambda.name} [${lambda.package}/${lambda.id}]`}
                        secondary={`${lambda.tags.map(x => `#${x}`).join(", ")} ${lambda.description}`}
                      />
                  </ListItemButton>
                  ))}
                </List>
              )}
          </>
        )
      }
    </div>
  );
}