import { Lambda } from "../../fake-api";

export const DEFAULT_STATE: Omit<State, 'package' | 'id'> = {
  inProgress: true,
  error: null,
  lambda: null,
  name: "",
  description: "",
  tags: "",
  code: "",
  payload: "{ }",
  options: { 
    tab: 'main',
    consoleVisible: true
  }
};

export type State = EditorFields & {
  package: string,
  id: string,
  inProgress: boolean,
  error: string | null,
  lambda: Lambda | null,
  options: EditorOptions
};

export type EditorFields = {
  name: string,
  description: string,
  tags: string,
  code: string,
  payload: string
};

export type EditorOptions = {
  tab: 'main' | 'payload',
  consoleVisible: boolean
};