export default `
# SSO Database

\`\`\`mermaid
erDiagram
Users {
  id VARCHAR
  status INTEGER
  isActive BOOL   
  payload JSONB  
}
Auth {
  id VARCHAR
  user VARCHAR
  type VARCHAR
  isActive BOOL
  payload JSONB
}
Auth }o--|| Users : user
Groups {
  id VARCHAR
  owner VARCHAR
  title VARCHAR
  isPersonal BOOL
  isActive BOOL
  payload JSONB
}
Groups }o--|| Users : owner
GroupUsers {
  id VARCHAR
  group VARCHAR
  user VARCHAR
  payload JSONB
}
GroupUsers }o--|| Groups : group
GroupUsers }o--|| Users : user
Permissions {
  id VARCHAR
  group VARCHAR
  entity VARCHAR
  permission VARCHAR
  payload JSONB
}
Permissions }o--|| Groups : group
Sessions {
  id VARCHAR
  secret VARCHAR
  auth VARCHAR
  start TIMESTAMP
  due TIMESTAMP
}
History {
  date TIMESTAMP
  session VARCHAR
  type VARCHAR
  payload JSONB
}
\`\`\`
` 