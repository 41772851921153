import { Action, State } from ".";

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'set-q':
      return { ...state, q: action.payload, error: null, isReady: false };

    case 'set-filter-visible':
      return { ...state, filterVisible: action.payload };

    case 'set-is-ready':
      return { ...state, isReady: action.payload };

    case 'set-page':
      return { ...state, page: action.payload };

    case 'set-list':
      return state.q === action.payload.q
        ? { ...state, list: action.payload.list, isReady: true }
        : { ...state };

    case 'set-selected':
      return { ...state, selected: action.payload };

    case 'set-error':
      return state.q === action.payload.q
        ? { ...state, error: action.payload.error, isReady: true }
        : { ...state };

    default:
      throw new Error(`Unhandled action type: ${JSON.stringify(action['type'])}`);
  }
}
