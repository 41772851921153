import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: { 
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 64px)'
  },
  main: {
    flex: 1
  },
  bottom: {
    height: 280,
    borderTop: 'solid 1px rgba(0, 0, 0, 0.24)',
    transition: 'height ease-in-out 300ms'
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row'
  },
  bottomHidden: {
    height: 48,
  }
});