import { ApiError } from './error';

export class API {
  constructor (
    public prefix: string
  ) { }

  async execute <T>(
    uri: string,
    method: string,
    query: Record<string, unknown> | null,
    body: unknown
  ): Promise<T> {
    const queryParts = [];
    for (const key in (query || {})) {
      if (query[key] == null) {
        continue;
      }

      queryParts.push(`${key}=${query[key].toString()}`);
    }

    const response = await fetch(
      `${this.prefix}${uri}${queryParts.length > 0 ? `?${queryParts.join('&')}` : ''}`, 
      {
        method,
        body: body == null ? null : JSON.stringify(body)
      }
    );
    
    const responseBody = await response.text();
    
    if (response.status !== 200) {
      throw new ApiError(response.status, responseBody);
    }

    if (responseBody === "") {
      return null;
    }

    try {
      return JSON.parse(responseBody);
    } catch (e) {
      throw new ApiError(500, `INVALID JSON="${body}"`)
    }
  }
}