import { Lambda } from "../fake-api";

export const DEFAULT_STATE: State = {
  q: '',
  filterVisible: false,
  page: 0,
  list: [],
  selected: null,
  count: 0,
  pageSize: 10,
  isReady: false,
  error: null
};

export type State = {
  q: string,
  filterVisible: boolean,
  page: number,
  list: Lambda[],
  selected: { package: string, id: string } | null,
  count: number,
  pageSize: number,
  isReady: boolean,
  error: string | null
};