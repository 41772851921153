import { User } from "@narayana/sso-api";
import { Dispatch } from "./reducer";
import { BoardApplication, BoardCoord } from "./state";

export interface IAction<T, P> {
  type: T,
  payload: P
};

export type Action<T = unknown> = AInstall<T> 
  | ADelete 
  | AStart<T>
  | ASetUset
  | ASetMessage;

export type AInstall<T = unknown> = IAction<"install", BoardApplication<T>>;
export type ADelete = IAction<"delete", BoardCoord>;
export type AStart<T = unknown> = IAction<"start", BoardApplication<T> | null>;
export type ASetUset = IAction<"set-user", User | null>;
export type ASetMessage = IAction<"set-message", string>;

export class Actions {
  constructor (
    private dispatch: Dispatch
  ) {}

  install = (application: BoardApplication) => {
    this.dispatch({ type: 'install', payload: application })
  }

  delete = (coord: BoardCoord) => {
    this.dispatch({ type: 'delete', payload: coord })
  }

  start = (uri: BoardApplication) => {
    this.dispatch({ type: 'start', payload: uri })
  }

  stop = () => {
    this.dispatch({ type: 'start', payload: null })
  }

  setUser = (user: User | null) => {
    this.dispatch({ type: 'set-user', payload: user })
  }

  setMessage = (message: string) => {
    this.dispatch({ type: 'set-message', payload: message })
  }
}