export default `
# ToDo

- Ability to check a group of permissions
- ApiKeys
- Create indexes and profile sql
- Permissions/delete (change the permissions check to <entity>#groups)
- Metamask authentication
- Oauth 2.0 authentication
- Oauth 2.0 provider
`