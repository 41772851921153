import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO, Session, User, UserPayload } from "..";

@ApiEntity("Password", "Methods for password auth and managment")
export class APIPassword {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Method for get users salt",
    [ ],
    "user-id",
    { "salt": "user-salt" } as PasswordSaltResponse
  )
  async salt (id: string): Promise<PasswordSaltResponse> {
    return this.api.execute(`/password/salt/${id}`, "GET", null, null);
  }
  
  @ApiMethod(
    "Method for sign-in",
    [ ],
    {
      user: "<user-id>",
      hash: "<hash>",
    } as PasswordSignInRequest,
    {  
      session: { id: "<session-id>", auth: "<session-auth>", start: "<session-start>", due: "<session-due>" },
      user: { id: "<user-id>", status: 0, isActive: true, payload: { a: 42 } }
    } as PasswordSignInResponse<{ a: number }>
  )
  async signIn<T = UserPayload> (
    request: PasswordSignInRequest
  ): Promise<PasswordSignInResponse<T>> {
    return this.api.execute("/password/sign-in", "POST", null, request);
  }

  @ApiMethod(
    "Method for change password",
    [ ],
    {
      hash: "<hash>",
      newSalt: "<hashSalt>",
      newHash: "<newHash>",
    } as PasswordChangeRequest,
    undefined
  )
  async change(request: PasswordChangeRequest): Promise<void> {
    return this.api.execute("/password", "PUT", null, request);
  }
}

/* salt */
export type PasswordSaltResponse = {
  salt: string
};

/* sign in */
export type PasswordSignInRequest = {
  user: string,
  hash: string,
};

export type PasswordSignInResponse<T = unknown> = {
  session: Session,
  user: User<T>
};

/* change */
export type PasswordChangeRequest = {
  hash: string,
  newSalt: string,
  newHash: string
};