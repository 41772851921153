import { API } from "../api";
import { ApiEntityDescription } from "./entity";
import { ApiMethodDescription } from "./method";
import { ApiServiceDescription } from "./service";
import { API_ENTITY_SYMBOL, API_SERVICE_SYMBOL } from "./symbols";

export class ApiDescription {
  constructor (
    public secrices: API[]
  ) { }

  getServices (): ServiceDescription[] {
    const result: ServiceDescription[] = [];

    for (const service of this.secrices) {
      if (service.constructor[API_SERVICE_SYMBOL] == null) {
        console.warn(`Service has no description`, service)
        continue;
      }

      const desc = service.constructor[API_SERVICE_SYMBOL];
      result.push({ 
        ...desc, 
        id: desc.name.toLowerCase(),
        instance: service
      });
    }

    return result;
  }

  entities (): EntityDescription[] {
    const result: EntityDescription[] = [];

    for (const service of this.getServices()) {
      for (const key in service.instance as Record<string, unknown>) {
        const property = service.instance[key];
        if (typeof property !== 'object' || property.constructor[API_ENTITY_SYMBOL] == null) {
          continue;
        }

        const entity: ApiEntityDescription = property.constructor[API_ENTITY_SYMBOL];
        result.push({ 
          ...entity, 
          id: `${service.id}_${entity.name.toLowerCase()}`,
          instance: property, service 
        });
      }
    }

    return result;
  }

  methods (): MethodDescription[] {
    const result: MethodDescription[] = [];

    for (const entity of this.entities()) {
      for (const method of entity.methods) {
        result.push({ 
          ...method,
          id: `${entity.id}_${method.name.toLowerCase()}`,
          entity, 
        })
      }
    }
    
    return result;
  }
}

export type ServiceDescription<T = unknown> = ApiServiceDescription & { 
  id: string,
  instance: T 
};

export type EntityDescription<T = unknown, R = unknown> = ApiEntityDescription & { 
  id: string,
  instance: T, 
  service: ServiceDescription<R> 
};

export type MethodDescription<T = unknown, R = unknown> = ApiMethodDescription & { 
  id: string,
  entity: EntityDescription<T, R> 
};