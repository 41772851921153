import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO } from "..";

@ApiEntity("GroupUsers", "Methods for managing users in group")
export class APIGroupUsers {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Method for add user to group (allowed only for group owner)",
    [ "personal#*", "personal#groups" ],
    {
      group: "<group-id>",
      user: "<user-id>",
      payload: { answer: 42 }
    } as GroupUserCreateRequest,
    { 
      id: "<group-id>:<user-id>",
      group: "<group-id>",
      user: "<user-id>",
      payload: { answer: 42 }
    } as GroupUser
  )
  async create (request: GroupUserCreateRequest): Promise<GroupUser> {
    return await this.api.execute(`/group-users`, "POST", null, request);
  }

  @ApiMethod(
    "Method for delete user from group (allowed only for group owner)",
    [ "personal#*", "personal#groups" ],
    {
      group: "<group-id>",
      user: "<user-id>",
    } as GroupUserCreateRequest,
    undefined
  )
  async delete ({ group, user }: { group: string, user: string }): Promise<void> {
    await this.api.execute(`/group-users/${encodeURIComponent(`${group}:${user}`)}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for get GroupUser (allowed only for group member)",
    [ "personal#*", "personal#groups" ],
    {
      group: "<group-id>",
      user: "<user-id>",
    } as GroupUserCreateRequest,
    { 
      id: "<group-id>:<user-id>",
      group: "<group-id>",
      user: "<user-id>",
      payload: { answer: 42 }
    } as GroupUser
  )
  async get ({ group, user }: { group: string, user: string }): Promise<GroupUser> {
    return await this.api.execute(`/group-users/${encodeURIComponent(`${group}:${user}`)}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting the number of users in group (allowed only for group member)",
    [ "personal#*", "personal#groups" ],
    {
      group: "<group-id>",
      q: "user query",
    } as GroupUsersFilterCount,
    { count: 42  }
  )
  async count (filter: GroupUsersFilterCount): Promise<{ count: number }> {
    return await this.api.execute(`/group-users/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for getting a list of users in group (allowed only for group member)",
    [ "personal#*", "personal#groups" ],
    {
      group: "<group-id>",
      q: "user query",
      limit: 10,
      offset: 0
    } as GroupUsersFilter,
    [
      { 
        id: "<group-id>:<user-id>",
        group: "<group-id>",
        user: "<user-id>",
        payload: { answer: 42 }
      } 
    ] as GroupUser[]
  )
  async list (filter: GroupUsersFilter): Promise<GroupUser[]> {
    return await this.api.execute(`/group-users`, "GET", filter, null);
  }
}

export type GroupUserCreateRequest<T = Record<string, unknown>> = {
  group: string,
  user: string,
  payload: T
};

export type GroupUsersFilterCount = {
  group: string,
  q: string
}

export type GroupUsersFilter = GroupUsersFilterCount & {
  limit: number,
  offset: number
}

export type GroupUser<T = GroupUserPayload> = {
  id: string;
  group: string;
  user: string;
  payload: T;
}

export type GroupUserPayload = Record<string, unknown>;