import CallIcon from "@mui/icons-material/Call";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import CallReceivedOutlinedIcon from "@mui/icons-material/CallReceivedOutlined";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Avatar from "@mui/material/Avatar";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";

import { AppProps } from "../..";
import { useStyles } from "./styles";
import { useData } from "./useData";
import IconButton from "@mui/material/IconButton";

export function Calls (props: AppProps) {
  const styles = useStyles();
  const { 
    isConnected,
    history, 
    phone, 
    session,
    duration,
    setPhone,
    handlePhoneChange,
    handleCall,
  } = useData(props);
  
  return (
    <div className={styles.root}>
      <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
        <OutlinedInput
          id="phone_number"
          autoFocus
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <FiberManualRecordIcon sx={{ color: isConnected ? '#2e7d32' : 'red' }} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton disabled={phone === '' || !isConnected} color="primary" onClick={handleCall}>
                <CallIcon color="inherit" />
              </IconButton>
            </InputAdornment>
          }
          value={phone}
          onChange={handlePhoneChange}
        />
      </FormControl>
      {session == null 
        ? (
          <>
            <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 2 }}>
              History
            </Typography>
            <List>
              {history.map(({ type, phone, name, duration }, index) => (
                <ListItem 
                  key={index}
                  alignItems="flex-start" 
                  sx={{ cursor: "pointer" }}
                  onClick={() => setPhone(phone)}
                >
                  <ListItemAvatar>
                    <Avatar>
                      {type === 'call-in' ? <CallReceivedOutlinedIcon /> : <CallMadeOutlinedIcon />}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={name || phone}
                    secondary={name ? phone : null}
                  />
                </ListItem>
              ))}
            </List>
          </>
        )
        : (
          <div>
            {duration}
          </div>
        )
      }
    </div>
  );
}