import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO } from "..";

@ApiEntity("Entities", "Methods for managing entities")
export class APIEntities {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Method allows to create a set of groups with preconfigured permissions",
    [ "<entity>#*", "<entity>#groups" ],
    {
      entity: "wallets",
      id: "wlt-id",
      user: "alice",
      groups: [
        { id: "wallets:wlt-id:owner", title: "wlt-id:owner", isPersonal: true, permissions: [ "*" ] },
        { id: "wallets:wlt-id:update", title: "wlt-id:update", isPersonal: false, permissions: [ "create", "update", "read" ] },
        { id: "wallets:wlt-id:read", title: "wlt-id:read", isPersonal: false, permissions: [ "read" ] },
      ]
    } as EntityCreateRequest,
    undefined
  )
  async create (request: EntityCreateRequest): Promise<void> {
    await this.api.execute(`/entities`, "POST", null, request);
  }
}

export type EntityCreateRequest = {
  entity: string,
  id: string,
  user: string,
  groups: EntityGroup[]
};

export type EntityGroup = {
  id: string,
  title: string,
  isPersonal: boolean,
  permissions: string[]
};