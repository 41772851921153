import { parseLocation } from "../../../utils";

export const defaultState = (): State => {
  const route = parseLocation();
  const invite = route.query.invite || '';

  return {
    page: invite === '' ? "sign-in" : 'sign-up',
    login: "",
    password: "",
    passwordVisible: false,
    invite,
    inProgress: false,
    error: null
  };
};

export type State = {
  page: "sign-in" | "sign-up",
  login: string,
  password: string,
  passwordVisible: boolean,
  invite: string,
  inProgress: boolean,
  error: { 
    field: string | null,
    message: string
  } | null
};

