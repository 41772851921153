import { User } from "@narayana/sso-api";

import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { Actions, State, useData } from "./useData";
import { useStyles } from "./styles";

export function Sign (props: SignProps) {
  const { onUserChanged } = props;
  const styles = useStyles();
  const { state, actions, handleSignIn, handleSignUp } = useData(props);

  return (
    <div className={styles.root}>
      <div className={styles.form}>
        {state.page === 'sign-in'
          ? <SignIn state={state} actions={actions} onUserChanged={onUserChanged} onSignIn={handleSignIn} />
          : <SignUp state={state} actions={actions} onUserChanged={onUserChanged} onSignUp={handleSignUp} />}
      </div>
    </div>
  )
}

export type SignProps = {
  onUserChanged: (user: User | null, message: string) => void
}

export type SignPageProps = {
  state: State,
  actions: Actions,
  onUserChanged: (user: User | null, message: string) => void
};

export type SignUpProps = {};