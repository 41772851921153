import { Action } from "./actions";
import { BoardCoord, State } from "./state";

export const reducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case 'delete': 
      state.board.delete(payload);
      return { ...state };

    case 'install': 
      let installed = false;
      for (let row = 0; row < ROWS; row++) {
        for (let column = 0; column < COLUMNS; column++) {
          let coord: BoardCoord = `${row}_${column}`;
          if (state.board.has(coord)) {
            continue;
          }

          installed = true;
          state.board.set(coord, payload);
          break;
        }
      }

      if (!installed) {
        state.notifications.unshift({ 
          type: "error", 
          application: "system/ui", 
          date: new Date(), 
          message: `Installation failed, no free display space ${JSON.stringify(payload)}`
        });
      }

      return { ...state };

    case 'start':
      return { ...state, active: payload };

    case 'set-user':
      return { ...state, user: payload, isUserReady: true }

    case 'set-message':
      return { ...state, message: payload }

    default:
      throw new Error(`Unhandled action: ${JSON.stringify(type)}`)
  }
};

export type Dispatch<T = unknown> = (action: Action<T>) => void;

export const COLUMNS = 4;
export const ROWS = 5;