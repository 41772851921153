import { ApiEntityConstructor, ApiEntityDescription } from "./entity";
import { API_ENTITY_SYMBOL } from "./symbols";

export const ApiMethod = (
  description: string,
  permissions: string[],
  request: unknown,
  response: unknown,
) => {
  let processed = false;

  return (target: unknown, propertyKey: string, _: PropertyDescriptor) => {
    if (processed) {
      return;
    }

    processed = true;
    const ctr = target.constructor as ApiEntityConstructor;

    ctr[API_ENTITY_SYMBOL] = ctr[API_ENTITY_SYMBOL] || {} as ApiEntityDescription;
    ctr[API_ENTITY_SYMBOL].methods = ctr[API_ENTITY_SYMBOL].methods || [];
    ctr[API_ENTITY_SYMBOL].methods.push({ 
      name: propertyKey,
      description,
      permissions,
      request,
      response
    });
  }
}

export type ApiMethodDescription = {
  name: string,
  description: string,
  permissions: string[],
  request: unknown,
  response: unknown,
}