import { ApiServiceConstructor, ApiServiceDescription } from "./service";
import { API_SERVICE_SYMBOL } from "./symbols";

export const Article = <T>(
  route: string,
  name: string,
  article: string
) => 
  (constructor: T) => {
    const ctr = constructor as ApiServiceConstructor;

    ctr[API_SERVICE_SYMBOL] = ctr[API_SERVICE_SYMBOL] || ({ articles: [] } as unknown as ApiServiceDescription);
    ctr[API_SERVICE_SYMBOL].articles.unshift({ route, name, article });
  }

export type ArticleDescription = {
  route: string,
  name: string,
  article: string,
}