import { useEffect, useMemo, useReducer } from 'react';
import { Actions } from './actions';
import { reducer } from './reducer';
import { defaultState } from './state';
import { API } from '../../api';

export * from './actions';
export * from './reducer';
export * from './state';

export function useData () {
  const [ state, dispatch ] = useReducer(reducer, defaultState());
  const actions = useMemo(() => new Actions(dispatch), [ dispatch ]);

  useEffect(() => {
    API.sso.user.me()
      .then((response) => actions.setUser(response.user))
      .catch(() => actions.setUser(null));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { state, actions };
}