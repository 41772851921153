import { useCallback, useEffect, useMemo, useReducer } from "react";
import { FakeApi } from "../../fake-api";
import { reducer } from "./reducer";
import { DEFAULT_STATE, State } from "./state";
import { Actions } from "./actions";

export * from './actions'
export * from './state'
export * from './reducer'

export function useData (api: FakeApi, packageId: string, id: string) {
  const [ state, dispatch ] = useReducer(reducer, { ...DEFAULT_STATE, package: packageId, id });
  const actions = useMemo(() => new Actions(dispatch), [ dispatch ]);

  const handleConsoleShow = useCallback(() => actions.setOptions('consoleVisible', true), [ actions ]);
  const handleConsoleHide = useCallback(() => actions.setOptions('consoleVisible', false), [ actions ]);
  const handleTabChanged = useCallback(
    (_: unknown, value: unknown) => actions.setOptions('tab', value as State['options']['tab']), 
    [ actions ]
  );

  useEffect(
    () => {
      // api
    },
    [ packageId, id ] 
  );

  console.log(state.options)

  return {
    state,
    actions,
    handleConsoleShow,
    handleConsoleHide,
    handleTabChanged
  }
}