import Alert from "@mui/material/Alert";
import { useStyles } from "./styles";

export function NotImplemented ({ message }: NotImplementedProps) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Alert severity="error">
        {message || 'Not implemeted'}
      </Alert>
    </div>
  )
}

export type NotImplementedProps = {
  message?: string
};