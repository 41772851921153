import { Action } from "./actions";
import { State } from "./state";

export const reducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case 'goto':
      return { 
        ...state, 
        page: payload,
        login: "",
        password: "",
        passwordVisible: false
      };

    case 'set':
      return { ...state, [payload.key]: payload.value, error: null }

    case 'set-error':
      return { ...state, error: payload }

    case 'set-password-visible':
      return { ...state, passwordVisible: payload }

    case 'set-in-progress':
      return { ...state, inProgress: payload }

    default:
      throw new Error(`Sing: unhandled action type: ${type}`)
  }
}

export type Dispatch = (action: Action) => void;