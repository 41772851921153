import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO } from "..";

@ApiEntity("Tokens", "Methods for validate permissions")
export class APITokens {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Method for validate permissions. Returns a token if at least one permission is found for the user",
    [ ],
    {
      permissions: [
        [ "<enitity-id>", "<permission-id>" ],
        [ "<enitity-id>", "<amother-permission-id>" ]
      ]
    } as TokensGetRequest,
    { 
      user: "<user-id>",
      session: "<session-id>"
    } as TokensGetResponse
  )
  async get(request: TokensGetRequest): Promise<TokensGetResponse> {
    return this.api.execute("/tokens", "POST", null, request);
  }
}

export type TokensGetRequest = {
  permissions: [ string, string ][],
};

export type TokensGetResponse = {
  user: string,
  session: string,
};