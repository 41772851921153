import CallIcon from '@mui/icons-material/Call'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { AppProps } from "../..";
import { useStyles } from "./styles";

export function Contacts ({ actions }: AppProps) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography variant="h5">
        Contacts
      </Typography>
      <List>
        {CONTACTS.map(({ name, protocol, message, phone }, index) => 
          <ListItem alignItems="flex-start" key={index}>
            <ListItemAvatar>
              <Avatar>
                {name[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={name}
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {protocol}:
                  </Typography>
                  {` ${message}`}
                </>
              }
            />
            <IconButton 
              onClick={() => actions.start({  
                name: "Calls",
                icon: "PhoneIcon",
                uri: { type: 'fake', payload: { application: "calls", phone } }
              })}
            >
              <CallIcon />
            </IconButton>
          </ListItem>
        )}
      </List>
    </div>
  )
}

const CONTACTS: Contact[] = [
  {
    name: "Jay",
    protocol: "jabber",
    message: "snoogans",
    phone: "79051144522"
  },
  {
    name: "Bob",
    protocol: "sms",
    message: "Tickets? Since when did they start charging for the bus?",
    phone: "79051144523"
  },
  {
    name: "Si",
    protocol: "sms",
    message: "I love FreeSwitch!",
    phone: "37257032800"
  },
  {
    name: "Janifer Aniston",
    protocol: "call",
    message: "[0:48]",
    phone: "79051144522"
  },
]

type Contact = {
  name: string,
  protocol: string,
  message: string,
  phone: string
};