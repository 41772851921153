import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSSO } from "..";

@ApiEntity("Users", "Methods for managing users")
export class APIUsers {
  constructor (
    public api: ApiSSO
  ) { }

  @ApiMethod(
    "Method for create user",
    [ "users#create" ],
    {
      id: "alice",
      passwordSalt: "salt",
      passwordHash: "hash",
      payload: { a: 42 }
    } as UserCreateRequest,
    { 
      id: "alice",
      status: 0,
      isActive: true,
      payload: { a: 42 }
    } as User
  )
  async create<T> (request: UserCreateRequest<T>): Promise<User> {
    return this.api.execute("/users", "POST", null, request);
  }

  @ApiMethod(
    "Method for update user",
    [ "users#update" ],
    {
      id: "alice",
      status: 2,
      payload: { a: 42 }
    } as UserUpdateRequest,
    undefined
  )
  async update (request: UserUpdateRequest): Promise<void> {
    return this.api.execute("/users", "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete user",
    [ "users#delete" ],
    "<user-id>",
    undefined
  )
  async delete (id: string): Promise<void> {
    return this.api.execute(`/users/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for restore user",
    [ "users#restore" ],
    "<user-id>",
    undefined
  )
  async restore (id: string): Promise<void> {
    return this.api.execute(`/users/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method for get user",
    [ "users#read" ],
    "<user-id>",
    { 
      id: "alice",
      status: 0,
      isActive: true,
      payload: { a: 42 }
    } as User
  )
  async get (id: string): Promise<User> {
    return this.api.execute(`/users/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting number of users",
    [ "users#read" ],
    {
      q: '',
      status: 0,
      is_active: true,
    } as UsersListFilterCount,
    { count: 42 }
  )
  async count (filter: UsersListFilterCount): Promise<{ count: number }> {
    return this.api.execute(`/users/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for get users list",
    [ "users#read" ],
    {
      q: '',
      status: 0,
      is_active: true,
      limit: 10,
      offset: 0
    } as UsersListFilter,
    [
      { 
        id: "alice",
        status: 0,
        isActive: true,
        payload: { a: 42 }
      }
    ] as User[]
  )
  async list (filter: UsersListFilter): Promise<User[]> {
    return this.api.execute(`/users`, "GET", filter, null);
  }
}

export type User<T = UserPayload> = {
  id: string;
  status: number;
  isActive: boolean;
  payload: T;
}

export type UserCreateRequest<T = UserPayload> = {
  id: string;
  passwordSalt: string;
  passwordHash: string;
  payload: T;
}

export type UserUpdateRequest<T = UserPayload> = {
  id: string,
  status: number,
  payload: T,
}

export type UsersListFilterCount = {
  q?: string | null,
  status?: number | null,
  is_active?: boolean | null,
}

export type UsersListFilter = UsersListFilterCount & {
  limit: number,
  offset: number,
};

export type UserPayload = Record<string, unknown>;