import { User } from '@narayana/sso-api'

export const defaultState = (): State => {
  return {
    user: null,
    message: "",
    isUserReady: false,
    board: new Map([ 
      [ 
        "0_0",  
        {
          name: "Contacts",
          icon: "AccountBoxIcon",
          uri: { type: "fake", payload: { application: "contacts" } }
        }
      ],
      [ 
        "0_1",  
        {
          name: "Wallets",
          icon: "WalletIcon",
          uri: { type: "fake", payload: { application: "wallets" } }
        }
      ],
      [ 
        "0_2",  
        {
          name: "SIM Cards",
          icon: "SimCardIcon",
          uri: { type: "fake", payload: { application: "simcards" } }
        }
      ],
      [ 
        "0_3",  
        {
          name: "Jabber",
          icon: "J",
          uri: { type: "fake", payload: { application: "jabber" } }
        }
      ],
      [ 
        "1_0",  
        {
          name: "CRM",
          icon: "PermContactCalendarIcon",
          uri: { type: "fake", payload: { application: "CRM" } }
        }
      ],
      [
        "1_1",
        {
          name: "Lambda",
          icon: "λ",
          uri: { type: "fake", payload: { application: "lambda" } }
        }
      ],
      [ 
        "4_0",  
        {
          name: "Releases",
          icon: "NewReleasesIcon",
          uri: { type: "fake", payload: { application: "releases" } }
        }
      ] 
    ]),
    active: null,
    notifications: []
  }
};
 
export type State = {
  user: User | null,
  message: string,
  isUserReady: boolean,
  board: Board,
  active: BoardApplication | null,
  notifications: Notification[]
};

export type Board = Map<BoardCoord, BoardApplication>;

export type BoardCoord = `${number}_${number}`;

export type BoardApplication<T = unknown> = {
  name: string,
  icon: string,
  uri: ApplicationUri<T>
};

export type ApplicationUri<T = unknown> = {
  type: ApplicationUriType,
  payload: T
};

export type ApplicationUriType = 'fake' | 'wasm';

export type Notification = {
  type: NotificationType,
  application: string,
  date: Date,
  message: string
};

export type NotificationType = "error" | "warning" | "notification";