import { createUseStyles } from 'react-jss';
import { DISPLAY_STYLES } from '../../styles';

export const useStyles = createUseStyles({
  root: { 
    ...DISPLAY_STYLES,
  },
  form: {
    position: "absolute",
    left: 32,
    top: "50%",
    width: "calc(100% - 64px)",
    display: "flex",
    transform: "translate(0, -50%)",
    flexDirection: "column",
    gap: 24
  }
});