import { COLUMNS, ROWS } from "../application/useData";

export const DISPLAY_MENU_HEIGHT = 80;

export const DISPLAY_STYLES = {
  position: "fixed",
  left: "50%",
  top: 0,
  transform: "translate(-50%, 0)",
  width: `calc(min((100vh - ${DISPLAY_MENU_HEIGHT}px) * ${COLUMNS / ROWS}, 100vw))`,
  height: "100%"
};