import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { reducer } from './reducer';
import { DEFAULT_STATE, State } from './state';
import { Actions } from './actions';
import { FakeApi } from '../fake-api';

export * from './actions';
export * from './reducer';
export * from './state';

export function useData () {
  const [ state, dispatch ] = useReducer(reducer, DEFAULT_STATE);
  const actions = useMemo(() => new Actions(dispatch), [ dispatch ]);
  const api = useMemo(() => new FakeApi(API_PREFIX), [ ]);
  const timeoutRef = useRef(null as (number | null));

  const handleSearch = useCallback(
    (q: string, delay: boolean = true) => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      const filter = {
        q: [] as string[],
        tags: [] as string[]
      };
      for (const item of q.split(" ")) {
        const formatted = item.trim();
        if (formatted.length < 2) {
          continue;
        }

        if (formatted[0] === '#') {
          filter.tags.push(formatted.substring(1));
          continue;
        }

        filter.q.push(formatted);
      }

      timeoutRef.current = setTimeout(
        () => 
          Promise.all([ 
            api.list(filter.q.join(' '), filter.tags, state.page * state.pageSize, state.pageSize), 
            api.count(q, filter.tags) 
          ])
            .then(([ list, count ]) => actions.setList(q, list, count))
            .catch((e) => actions.setError(q, e)),
        delay ? DELAY : 0
      ) as unknown as number;
    }, 
    [ actions, api, state.page, state.pageSize ]
  );

  const handleQChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const q = e.currentTarget.value;
      actions.setQ(q);

      handleSearch(q);
    }, 
    [ actions, handleSearch ]
  );

  const handleSelectedChanged = useCallback(
    (selected: State['selected']) => {
      actions.setSelected(selected);

      if (selected == null) {
        handleSearch(state.q, false);
      }
    }, 
    [ actions, state.q, handleSearch ]
  );

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => { handleSearch("", false); }, [])

  return {
    state,
    api,
    handleQChanged,
    handleSelectedChanged
  }
}

const DELAY = 300;
const API_PREFIX = "";