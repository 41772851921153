export function parseLocation (): Route {
  const [ page, queryStr ] = decodeURI(window.location.hash.substring(3)).split('?');
  let query = {} as Record<string, string>;
  if (queryStr != null && queryStr.length > 0) {
    for (const part of queryStr.split('&')) {
      const [ name, value ] = part.split('=');

      query[decodeURIComponent(name)] = decodeURIComponent(value || '');
    }
  }

  return { 
    page: page[page.length - 1] === '/' ? page.substring(0, page.length - 1) : page, 
    query 
  }
}

export type Route = {
  page: string,
  query: Record<string, string | null | undefined>
};