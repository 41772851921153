import { createUseStyles } from 'react-jss';
import { DISPLAY_STYLES } from '../../styles';

export const useStyles = createUseStyles({
  root: { 
    ...DISPLAY_STYLES,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    overflow: "visible"
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  cell: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    zoom: 1,
    gap: 8,
    cursor: "pointer"
  },
  emptyCell: {
    cursor: "auto"
  },
  bottomNavigation: {
  }
});