import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

export function LoginPassword ({
  login,
  password,
  passwordVisible,
  disabled,
  error,
  onLoginChanged,
  onPasswordChanged,
  onHidePassword,
  onShowPassword
}: LoginPasswordProps) {
  return (
    <>
      <TextField 
        fullWidth
        label="Login"
        value={login}
        disabled={disabled}
        color={error?.field === 'login' ? 'error' : 'primary'}
        onChange={onLoginChanged}
      />
      <TextField 
        fullWidth
        label="Password"
        value={password}
        type={passwordVisible ? "text" : "password"}
        disabled={disabled}
        color={error?.field === 'password' ? 'error' : 'primary'}
        InputProps={{
          endAdornment: (
            <IconButton onClick={passwordVisible ? onHidePassword : onShowPassword}>
              {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          )
        }}
        onChange={onPasswordChanged}
      />
    </>
  )
}

export type LoginPasswordProps = {
  login: string,
  password: string,
  passwordVisible: boolean,
  disabled: boolean,
  error: {
    field: string | null,
    message: string
  } | null | undefined,
  onLoginChanged: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onPasswordChanged: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onShowPassword: () => void,
  onHidePassword: () => void,
};