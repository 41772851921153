import { API, ApiService, Article } from '@narayana/api'

import docs from './__docs'
import { APIInvites } from './invites'

export * from './invites';

@Article("concept", "Concept", docs.concept)
@Article("todo", "ToDo", docs.todo)
@Article("database", "Database", docs.database)
@ApiService("Invites", docs.readme)
export class ApiInvites extends API {
  public invites = new APIInvites(this);

  constructor (prefix: string) {
    super(prefix);
  }
}